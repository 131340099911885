/* styles.css atau file CSS terpisah */
.slide-in {
  transform: translateX(100%); /* Geser elemen ke kiri */
  transition: transform 0.3s ease-in-out;
}

.slide-in.active {
  transform: translateX(0); /* Kembali ke posisi awal */
}

.background {
  background-image: url("./images/bg.JPG"); /* Pastikan path gambar benar */
  background-size: cover; /* Menutupi seluruh elemen dengan latar belakang */
  background-position: center; /* Posisikan gambar di tengah */
  background-repeat: no-repeat; /* Menghindari pengulangan gambar */
  background-attachment: fixed; /* Memastikan background tidak bergerak saat scroll */
  width: 100vw; /* Lebar 100% dari viewport */
  height: 100vh; /* Tinggi 100% dari viewport */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5) !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.header-img {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top, right;
}
.emoji-404 {
  position: relative;
  animation: mymove 2.5s infinite;
}

@keyframes mymove {
  33% {
    top: 0px;
  }
  66% {
    top: 20px;
  }
  100% {
    top: 0px;
  }
}
@media only screen and (max-width: 444px) {
  .header-img {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: -700px;
  }
}
