/* whell.css */


.background {
  background-image: url("../images/bg.JPG"); /* Pastikan path gambar benar */
  background-size: cover; /* Menutupi seluruh elemen dengan latar belakang */
  background-position: center; /* Posisikan gambar di tengah */
  background-repeat: no-repeat; /* Menghindari pengulangan gambar */
  background-attachment: fixed; /* Memastikan background tidak bergerak saat scroll */
  width: 100vw; /* Lebar 100% dari viewport */
  height: 100vh; /* Tinggi 100% dari viewport */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.container {
  position: relative;
  z-index: 1; /* Pastikan konten berada di atas background */
}


.spin-button {
  position: absolute;
  bottom: 20px; /* Jarak dari bawah, sesuaikan jika perlu */
}

.relative {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}
